import { render, staticRenderFns } from "./MessageSentiments.vue?vue&type=template&id=5c9f94e8&scoped=true&"
import script from "./MessageSentiments.vue?vue&type=script&lang=js&"
export * from "./MessageSentiments.vue?vue&type=script&lang=js&"
import style0 from "./MessageSentiments.vue?vue&type=style&index=0&id=5c9f94e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c9f94e8",
  null
  
)

export default component.exports