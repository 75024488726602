<template>
  <div v-if="messageEmoji" class="message-emoji-container">
    <div class="message-emoji-box">
      <div class="message-emoji">{{ messageEmoji }}</div>
    </div>
  </div>
</template>

<script>
// const toEmoji = require('emoji-name-map')
const SENTIMENT_MAP = {
  1: '😩',
  2: '🙁',
  3: '',
  4: '',
  5: ''
}

export default {
  name: 'MessageSentiments',
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  computed: {
    messageEmoji() {
      if (this.message.plugins && this.message.plugins[0]) {
        // emotions
        // return toEmoji.get(
        //   this.message.plugins && JSON.parse(this.message.plugins[1]).output[0]
        // )

        // sentiments
        const sentimentPlugin = this.message.plugins.find(plugin => {
          const pluginObj = JSON.parse(plugin)
          return pluginObj.pluginId === 'sentiment'
        })
        const sentimentValue =
          sentimentPlugin && JSON.parse(sentimentPlugin).output
            ? JSON.parse(sentimentPlugin)
                .output.replace('stars', '')
                .trim()
            : undefined
        return sentimentValue ? SENTIMENT_MAP[sentimentValue] : null
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.message-emoji-box {
  background: #f1f1f1;
  border-radius: 3px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  //.message-emoji {
  //margin-right:5px;
  //}
  width: fit-content;
  padding: 2px 9px;
  .message-emoji {
    font-size: 0.9em;
  }
  .message-emoji-number {
    font-size: 0.9em;
  }
}
</style>
